import React from "react"
import { graphql } from "gatsby"
import { Row, Col, Hidden } from 'react-grid-system'

import Layout from "../components/layout"
import Image1 from "../images/onno_drenth_behandeling.png"
import Image2 from "../images/onno_drenth_wachtkamer.png"
import Image3 from "../images/onno_drenth_objecten.png"

const IndexPage = () => {
  return (
    <Layout>
      <div className="index-content">
        <Row>
          <Col lg={4} md={6}>
            <img src={Image1} alt="Onno Drenth Chiropraxie" />
          </Col>
          <Hidden md>
            <Col lg={4}>
              <img src={Image2} alt="Onno Drenth Chiropraxie" />
            </Col>
          </Hidden>
          <Col lg={4} md={6}>
            <img src={Image3} alt="Onno Drenth Chiropraxie" />
          </Col>
        </Row>
      </div>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
          }
        }
      }
    }
  }
`